import * as amplitude from '@amplitude/analytics-browser';
import type { Router } from 'vue-router';
import { snakeCase } from 'lodash';
import { AMPLITUDE_API_KEY } from '@/shared/config/env';
import { getUserId } from '@/shared/model/TokenService';
import type { UserId } from '@/shared/model/types/User';
import type { CompanyId } from '@/shared/model/types/Company';

interface EventAmplitude {
    type: string;
    properties?: Record<string, unknown>;
}

interface UserInfo {
    userId: UserId;
    name: string;
    position: string;
    department: string;
    companyId: CompanyId;
    companyName: string;
    email: string;
}

export default class Amplitude {
    private static initiated = false;

    static init(): void {
        const options: import('@amplitude/analytics-types').BrowserOptions | undefined = {
            minIdLength: 1,
            serverZone: 'EU',
            identityStorage: 'localStorage',
            autocapture: {
                attribution: true,
                pageViews: false,
                sessions: true,
                formInteractions: true,
                fileDownloads: true,
                // DOC: https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#track-element-interactions
                elementInteractions: true
            }
        };

        if (AMPLITUDE_API_KEY) {
            Amplitude.initiated = true;
            const userId = String(getUserId() ?? '');
            amplitude.init(AMPLITUDE_API_KEY, userId, options);
        }
    }

    static setUserId(id: string): void {
        if (!Amplitude.initiated) {
            return;
        }

        amplitude.setUserId(id);
    }

    static logEvent(event: EventAmplitude) {
        if (!Amplitude.initiated) {
            return;
        }

        const properties: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(event.properties ?? {})) {
            properties[snakeCase(key)] = value;
        }

        amplitude.logEvent(event.type, properties);
    }

    static pageView(router: Router, payload: Record<string, unknown>) {
        if (!Amplitude.initiated) {
            return;
        }

        const params: Record<string, unknown> = { ...payload };
        for (const [key, value] of Object.entries(router.currentRoute.value.params)) {
            params[snakeCase(key)] = String(value);
        }
        Amplitude.logEvent({
            type: 'page_view',
            properties: {
                ...params,
                route_name: router.currentRoute.value.name,
                page_title: router.currentRoute.value.meta?.title || document.title,
                page_location: window.location.href,
                page_path: window.location.pathname
            }
        });
    }

    static logout() {
        if (!Amplitude.initiated) {
            return;
        }

        amplitude.reset();
    }

    static setUserInfo(profile: UserInfo) {
        if (!Amplitude.initiated) {
            return;
        }

        const identifyEvent = new amplitude.Identify();
        for (const [key, value] of Object.entries(profile)) {
            identifyEvent.set(key, value);
        }
        amplitude.identify(identifyEvent);
    }
}
